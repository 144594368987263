import { Link } from "gatsby";
import React from "react";
import { Box } from "../elements/Box";
import { Button } from "../elements/Button";
import { Flex } from "../elements/Flex";
import { Image } from "../elements/Image";

const CategoryGridItem = ({
  img = "https://www.proudtocaredevon.org.uk/wp-content/uploads/sites/4/2021/08/y9DpT.jpg",
  cta,
  path,
}) => {
  return (
    <Link to={path}>
      <Box position="relative" width="100%" zIndex={-1}>
        <Image src={img} width="100%" height="auto" objectFit="cover" aspectRatio="12/9" />

        <Flex bottom={0} position="absolute" width="100%" height="100%" justifyContent="center" alignItems="flex-end">
          <Button mb={4} bg="white" color="teal" variant="rounded">
            {cta}
          </Button>
        </Flex>
      </Box>
    </Link>
  );
};

export default CategoryGridItem;
