import React from "react";
import { Box } from "../elements/Box";
import { Grid } from "../elements/Grid";
import CategoryGridItem from "./CategoryGridItem";

const CategoryGrid = ({ categories }) => {
  return (
    <Grid my={4} position="relative" display={["block", "grid"]} gridTemplateColumns="1fr 1fr">
      <Box
        position="absolute"
        bg="white"
        height="40px"
        width="100%"
        top="-1px"
        zIndex={50}
        style={{ clipPath: "polygon(0 0, 100% 0, 100% 0%, 0% 50%)" }}
      />

      {categories.map((props, index) => (
        <CategoryGridItem key={index} {...props} />
      ))}
    </Grid>
  );
};

export default CategoryGrid;
