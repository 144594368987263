import React from "react";
import WorkoutGuideSection from "../../components/WorkoutGuide";
import { Flex } from "../../elements/Flex";
import { Box } from "../../elements/Box";

import { Ul } from "../../elements/Ul";
import { Li } from "../../elements/Li";

import DownloadButtons from "../../components/DownloadButtons";
import workoutguide3mil from "../../images/guides/workoutguide3mil.jpg";
import lowerbodywaistguide from "../../images/guides/lowerbodywaistguide2.jpg";
import blackFridayGuide from "../../images/guides/black-friday-guide.jpg";
import isolationGuide from "../../images/guides/isolation-guide.jpg";
import { Text } from "../../elements/Text";
import HeroSection from "../../components_v2/HeroSection";
import SectionHeader from "../../components_v2/SectionHeader";
import workoutGuides from "../../images/v2/lilly/workoutGuides2.png";
import SEO from "../../components/SEO";
import FreeGuidesForm from "../../components_v2/FreeGuidesForm";
import ClippedPathContainer from "../../components_v2/ClippedPathContainer";
import ImageTextSection from "../../components_v2/ImageTextSection";
import AppStatistics from "../../components_v2/AppStatistics";
import CategoryGrid from "../../components_v2/CategoryGrid";
import appFeatures from "../../images/v2/app/phone21guide.png";
import resultsImg from "../../images/v2/app/twoPhones.png";
import checkIconGreen from "../../images/icons/check-green.png";
import checkIconGray from "../../images/icons/check-gray.png";

import { Grid } from "../../elements/Grid";
import { Image } from "../../elements/Image";

import bars from "../../images/v2/nutrition/bars.png";
import blackBand from "../../images/v2/fitness/blackBand.png";

import "./workout-guides.scss";

const categories = [
  {
    img: bars,
    cta: "SHOP Nutrition",
    path: "/shop/nutrition",
  },
  {
    img: blackBand,
    cta: "Shop Fitness",
    path: "/shop/fitness",
  },
];

const data = [
  // {
  //   title: "Black Friday Strong & Shred Guide",
  //   // subtitle: "7 DAY HOME GUIDE",
  //   image: blackFridayGuide,
  //   description:
  //     "This free 7 day guide is designed to help you build a strong and lean body whilst having fun with the LEAN familia. 7 days of live workouts and finishers to have you sweating and building strength. I hope you’re all ready for one epic burn and to release all those happy hormones. Be sure to check out the amazing Black Friday sales in the guide!",
  //   info: [],
  //   link: "https://assets.aflete.com/assets/downloads/J4AbiteXReZxEjmjyUCYBdNc6Bn7AyW16JrsWP3YXPAC8DHiydcAr5HkRGx09zrtz17oSnn4GtsQUuDQ3uFcEqhntHAMpMtCGcCO.pdf",
  // },
  {
    title: "2022 Home Isolation Guide",
    subtitle: "7 DAY HOME GUIDE",
    image: isolationGuide,
    description:
      "As many of you are now having to isolate over the holiday period, I wanted to spread some healthy & happy joy with you all! Introducing our brand new 7 Day 2022 Home Isolation Guide. The perfect home workout program made out of 30 minute workouts to leave you feeling strong and releasing all of those happy hormones. Strength training, Pilates, HIIT and more, this is the perfect guide if you’re needing a little pick me up. I’m here for you all!",
    moreInfo: (
      <>
        <Text mb={2}>
          Once this is over we have a brand new HUGE surprise challenge coming to the LEAN app and trust me when I say,
          you don’t want to miss out on this. I’ll be chatting more about this during this guide so you have all the
          details and are ready for one incredible start to 2022!
        </Text>
      </>
    ),
    info: [],
    link: "https://assets.aflete.com/assets/downloads/eJHSGbrqWWR4JUDLg9dltwAr9qdmc7acl7rxwSkMxd5M6DsbFd0aHWJgkxVN2CV8Fbok5kJKCjj950VjN3JxDLGsfekHxuP1GVgR.pdf",
  },
  {
    title: "LOWER BODY & WAIST GUIDE",
    // subtitle: "7 DAY HOME GUIDE",
    image: lowerbodywaistguide,
    subtitle: "Two week home guide",
    description:
      "This 2 week guide is a full body burn, with a focus on the waist and lower body, however all body parts are trained. This guide is designed to help you build a strong, lean physique, and most importantly enjoy the journey. Don’t forget to combine this guide with good nutrition and know that consistency is key.",
    moreInfo: null,
    info: [],
    link: "https://media.genflow.com/17---August-23----LWG---Lower-Body-%26-Waist-Guide.pdf",
  },

  {
    title: "3 MILLION SUBSCRIBERS GUIDE",
    subtitle: "7 DAY HOME GUIDE",
    image: workoutguide3mil,
    description: (
      <Flex flexDirection="column" maxWidth="900px" mt={3}>
        <Text mb={2}>We did it familia! We are 3 million strong. </Text>
        <Text mb={2}>
          Did you know that I started uploading workouts to YouTube over 4 years ago. I spent the first 3 years filming
          and editing to get just a handful of views on each video. There were times when I was incredibly close to
          giving up.
          {/* It took 3 years of uploading to grow our familia to 30,000 subscribers. It was so much graft, and I
          felt like I just wasn’t having my ‘break’. I would spend a full day filming, editing and uploading each
          workout, and it would get just a few views. But I was determined to find you and help you all! Despite the
          periods of doubt and considering going back to my day job as a Physiotherapist, I didn’t give up. LOOK AT US
          NOW… We are 3 million strong. I found you, and you found me familia, and I couldn’t be more grateful. */}
        </Text>
        {/* <Text mb={2}>
          Over the past 16 months we have grown from 30,000 to 3 million subscribers! Thank you for being part of this
          incredible journey, familia. I love you all more than you could imagine.
        </Text>
        <Text mb={2}>
          It’s time to celebrate with our LEAN 7 Day 3 Million Guide. All of the workouts are free and can be accessed
          on YouTube and also one free workout on the LEAN App.There’s also 15+ pieces of free content on the LEAN app
          for you to check out. If you’d like to unlock hundreds of hours of fresh workout content, hundreds of recipes,
          personalised workout and meal plans to help you achieve your goals, sign up to premium on the LEAN app for
          just 30p/ 45 cent per day.
        </Text>
        <Text>Not much else left to say other than… Let’s Do This!</Text> */}
      </Flex>
    ),
    moreInfo: (
      <>
        <Text mb={2}>
          It took 3 years of uploading to grow our familia to 30,000 subscribers. It was so much graft, and I felt like
          I just wasn’t having my ‘break’. I would spend a full day filming, editing and uploading each workout, and it
          would get just a few views. But I was determined to find you and help you all! Despite the periods of doubt
          and considering going back to my day job as a Physiotherapist, I didn’t give up. LOOK AT US NOW… We are 3
          million strong. I found you, and you found me familia, and I couldn’t be more grateful.
        </Text>
        <Text mb={2}>
          Over the past 16 months we have grown from 30,000 to 3 million subscribers! Thank you for being part of this
          incredible journey, familia. I love you all more than you could imagine.
        </Text>
        <Text mb={2}>
          It’s time to celebrate with our LEAN 7 Day 3 Million Guide. All of the workouts are free and can be accessed
          on YouTube and also one free workout on the LEAN App.There’s also 15+ pieces of free content on the LEAN app
          for you to check out. If you’d like to unlock hundreds of hours of fresh workout content, hundreds of recipes,
          personalised workout and meal plans to help you achieve your goals, sign up to premium on the LEAN app for
          just 30p/ 45 cent per day.
        </Text>
        <Text>Not much else left to say other than… Let’s Do This!</Text>
      </>
    ),
    info: [],
    link: "https://media.genflow.com/3+Million+Subscribers+Guide+V2.pdf",
  },
  {
    title: "HOT GIRL SUMMER SHRED",
    subtitle: "7 DAY HOME WORKOUT GUIDE",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/13_-_Hot_Girl_Summer_Shred_-_Square_480x480.jpg?v=1622373247",
    description:
      "SAVE THE DATE: THURSDAY 10 JUNE, THE LEAN APP FINALLY LAUNCHES!!! Personalised workout guides, personalised meal plans, physio & form tips, a recipe library and so much more. This Hot Girl Summer 7 Day Guide is our last pre LEAN App.",
    //Launch 7 day guide and I couldn't be more excited to blast through this week with you. We have so many incredible workouts to look forward to
    moreInfo: null,
    info: [
      "7 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts at home",
      "Live Q&A's with Lilly",
      "Journal",
      "Habit Tracker",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/13_-_May_30_-_LWG_-_Hot_Girl_Summer_Shred_V2.pdf?v=1622390642",
  },
  {
    title: "LEAN App Launch Date Reveal",
    subtitle: "7 Day Home Workout Guide",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/12_-_LEAN_App_Launch_Date_Reveal_-_Square_480x480.jpg?v=1621874909",
    moreInfo: null,
    description:
      "I can't believe I'm saying this BUT the LEAN App launch date is… Tune in on Wednesday to find out! Everything you need to live your fittest, healthiest and HAPPIEST life in one place. Watch this space Familia. The dream is becoming a reality. Let's do this!",
    info: [
      "7 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts at home",
      "Live Q&A's with Lilly",
      "Journal",
      "Habit Tracker",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/12_-_May_24_-_LWG_-_LEAN_App_Launch_Date_Reveal.pdf?v=1621875799",
  },
  {
    title: "The LEAN App is Coming!",
    subtitle: "7 Day Home Workout Guide",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/11_-_The_App_Is_Coming_-_Square_480x480.jpg?v=1621189026",
    description:
      "This week you will be getting a sneak peak into some of the exciting features of the brand new LEAN App. Live workouts, live cooking, live Q&As with our LEAN Nutritionist and more. I literally can't believe how excited I am to reveal everything familia! So much to come… Let's do this!",
    moreInfo: null,
    info: [
      "7 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live Nutrition Q&A",
      "Live Cooking Session with Lilly",
      "Journal",
      "Habit Tracker",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/11_-_May_17_-_LWG_-_The_App_Is_Coming.pdf?v=1621189089",
  },
  {
    title: "3 MILLION SUBSCRIBERS",
    subtitle: "7 Day Home guide",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/10_-_Summer_Shred_Starter_Guide_-_Square_480x480.jpg?v=1620634346",
    description: (
      <Flex flexDirection="column" maxWidth="900px" mt={3}>
        <Text mb={2}>
          Ready to kick start you summer shred? This guide has everything you need to have you feeling strong and
          glowing from the inside out. I'm in... Are you?
        </Text>

        <Ul style={{ "list-style-position": "inside" }}>
          <Li>7 Day Home Workout Guide</Li>
          <Li>New pre-recorded follow along workouts </Li>
          <Li>Journal</Li>
        </Ul>
      </Flex>
    ),
    moreInfo: null,
    info: ["7 Day Home Workout Guide", "New pre-recorded follow along workouts", "Journal", "Habit Tracker"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/10_-_May_10_-_LWG_-_Summer_Shred_Starter_7ef6d672-d874-407a-827d-52f8839b1e7d.pdf?v=1620643743",
  },

  {
    title: "It's You Against You",
    subtitle: "14 Day Home Guide",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/9_-_It_s_You_Against_You_-_Square_Cover_480x480.jpg?v=1619422386",
    description:
      "This guide is all about YOU. Your goals, your mind, and your body. It doesn't matter what the people around you are doing, it's YOU AGAINST YOU, and you will come out on top. Love you familia, let's do this.",
    info: ["14 Day Home Workout Guide", "New pre-recorded follow along workouts", "Journal", "Habit Tracker"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/9_-_April_25_-_LWG_-_It_s_You_Against_You.pdf?v=1619422404",
  },
  {
    title: "We're Going In",
    subtitle: "14 Day Home Guide",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/8_-_We_re_Going_In_14_Day_Home_Guide_-_Square_480x480.jpg?v=1618162493",
    description:
      "Over the next 14 Days, We're Going In! If like me, you've had a little break from exercise, or you're looking to increase the intensity, this is the guide for you! I'll be with you every step of the way. Let's Do This Familia.",
    info: ["14 Day Home Workout Guide", "New pre-recorded follow along workouts", "Journal", "Habit Tracker"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/8_-_April_11_-_LWG_-_We_re_Going_In.pdf?v=1618162460",
  },
  {
    title: "LEAN & Powerful Guide",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/7_-_LEAN_Powerful_Guide_-_Square_480x480.jpg?v=1616954533",
    description:
      "Think strong. Think power. Think LEAN. Across the next 14 days, You will be challenged with daily fun and effective workouts that will push your body, and way more importantly make you feel INCREDIBLE. I'm excited to go on this journey with you all, and I'm so proud of you for taking the steps to being your fittest, healthiest and happiest self.",
    info: ["14 Day Home Workout Guide", "New pre-recorded follow along workouts", "Journal", "Habit Tracker"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/7_-_Mar_29_-_LWG_-_LEAN_Powerful.pdf?v=1616954284",
  },
  {
    title: "Feel Sexy, Feel Strong 14 Day Guide",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/6_-_Square_WEB_-_Feel_Strong_Feel_Sexy_Guide_-_Cover_480x480.jpg?v=1615808392",
    description:
      "Over the next 14 days, we're going to be working on building your resilience. The Feel Sexy, Feel Strong 14 Day Guide focuses on sculpting and building lean muscles, to help you feel sexy and strong from the inside out.",
    info: [
      "14 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts",
      "Journal",
      "Habit Tracker",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/6_-_Mar_15_-_LWG_-_Feel_Strong_Feel_Sexy.pdf?v=1615808469",
  },
  {
    title: "Hourglass Building 14 Day Guide",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/5_-_Square_-_Hourglass_Building_Guide_-_WEB_480x480.jpg?v=1614609520",
    description:
      "The Hourglass Building 14 Day Guide is designed to help sculpt and build lean muscle. All muscles in the body will be worked with a particular focus on the core, glutes, shoulders and back.",
    info: [
      "14 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts",
      "Journal",
      "Habit Tracker",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/5_-_Mar_1_-_LWG_-_Hourglass_Building_Guide.pdf?v=1614609491",
  },
  {
    title: "14 Day Self Care Guide",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/4_-_Self_Care_Guide_-_Square_WEB_480x480.jpg?v=1613385187",
    description:
      "The 14 Day Self Care Guide is all about putting yourself first. Self care is not selfish. During this guide we're going to be doing way more than just exercise to look a certain way. We are going to be releasing those endorphins, looking after our mental health and putting aside well deserved time for ourselves.",
    info: [
      "14 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts",
      "Healthy Recipe",
      "Journal",
      "Habit Tracker",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/4_-_Feb_15_-_LWG_-_Self_Care_Guide.pdf?v=1613385139",
  },
  {
    title: "The Strong & Glow Guide Vol. 2",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/3_-_Strong_Glow_Vol_2_-_Square_Web_480x480.jpg?v=1612114457",
    description:
      "The Strong & Glow Guide Vol. 2, is a 14 day Home Workout Program designed for a full body burn. There will be live workouts, journaling and a habit tracker and a healthy recipe.",
    info: [
      "14 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts",
      "Healthy Recipe (sent to your email during the guide)",
      "Journal",
      "Habit Tracker",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/3_-_Feb_1_-_LWG_-_Strong_Glow_Vol_2.pdf?v=1612114301",
  },
  {
    title: "The Strong & Glow Guide",
    subtitle: "",
    image: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/2_-_Strong_Glow_-_Web_480x480.jpg?v=1610981793",
    description:
      "The Strong & Glow Guide, is a 14 day Home Workout Program designed for a full body tone. There will be live workouts, stretches, Nutrition Q and A's, journaling and a habit tracker and a healthy recipe.",
    info: [
      "14 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts",
      "Healthy Recipe",
      "Journal",
      "Habit Tracker",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/2_-_January_18_-_LWG_-_Strong_Glow.pdf?v=1610981863",
  },
  {
    title: "2021 New Years Home Workout Guide",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/1_-_New_Years_Guide_-_IG_Cover_v2_480x480.jpg?v=1609662046",
    description:
      "The 2021 New Years Home Workout Guide, is a 14 day Home Workout Program designed for a full body fat burn. There will be live workouts, Q and A's, journaling and a habit tracker, to help you set your new years goals in motion.",
    info: [
      "14 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts",
      "Journal",
      "Habit Tracker",
      "Live Q&A's",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/1_-_January_4_-_LWG_-_New_Year_Guide.pdf?v=1609661980",
  },
  {
    title: "12 Days of LEANmas - Full body Sculpt",
    subtitle: "",
    image: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/LEANmas_-_Square_480x480.jpg?v=1607886225",
    description:
      "On the lead up to Christmas, we are taking on the LEANmas workout challenge. This challenge will take you through to Christmas, where we will celebrate together with a workout. Get ready for a full body sculpt, and I can't wait to take on the challenge with you!",
    info: ["New pre-recorded follow along workouts", "Live workouts"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/34_-_December_14_-_LEAN_Weekly_Guide_-_12_Days_of_LEANmas.pdf?v=1607886209",
  },
  {
    title: "7 Day Lilly Sabri x Linda Sun Challenge",
    subtitle: "",
    image: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/LS_x_LS_-_Square_480x480.jpg?v=1607256737",
    description:
      "Introducing the LS X LS Guide. Are you taking on the Challenge? This is everything you need to live your healthiest and happiest lifestyle. #lsxlschallenge to be featured on instagram Let's do this!!",
    info: [
      "Live Workouts",
      "Q&As with Lilly & Linda",
      "Live Cook Offs with Lilly & Linda",
      "6 Healthy Recipes",
      "Journal and Habit Tracker",
      "A HUGE LEAN band giveaway. Check out @lillysabri and @lindasunyt on Instagram to enter!",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/33_-_Lilly_Sabri_x_Linda_Sun_-_LEAN_Weekly_Guide.pdf?v=1607256693",
  },
  {
    title: "14 Day At Home Workout Guide",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/4x5_-_14_Day_At_Home_Workout_Guide_480x480.jpg?v=1606078563",
    description:
      "The 14 Day At Home Workout Guide is a full body fat burn program, designed to target your entire body, with different styles of training across 14 days. There will be live workouts, Q and A's, live cook-offs, journaling, a habit tracker, and new delicious healthy recipes to try.",
    info: [
      "14 Day At Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts",
      "Journal",
      "Habit Tracker",
      "2 Healthy Recipes",
      "Live Q&A's and Cook-offs",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/14_Day_At_Home_Workout_Guide_-_LEAN_with_lilly.pdf?v=1606078394",
  },
  {
    title: "14 Day Lockdown Home Workout Guide",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Square_-_14_Day_Lockdown_Guide_480x480.jpg?v=1604424208",
    description:
      "The 14 Day Lockdown Workout Guide is designed to target all muscle groups in the body, with different styles of training across 14 days. There will be live workouts, Q and A's, live cook-offs, journaling, a habit tracker, and new delicious healthy recipes to try.",
    info: [
      "14 Day Home Workout Guide",
      "New pre-recorded follow along workouts",
      "Live workouts",
      "Journal",
      "Habit Tracker",
      "3 Healthy Recipes",
      "Live Q&A's and Cook-offs",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/14_Day_Lockdown_Home_Workout_Guide_-_LEAN_with_lilly.pdf?v=1604425170",
  },
  {
    title: "Fat Burn & Tone Bootcamp",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/28_-_October_26_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1603653595",
    description: "This week, we're going in! We're working the entire body and this is going to be INTENSE!",
    info: ["New pre-recorded follow along workouts", "Live workouts"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/28_-_LEAN_Weekly_Guide_-_October_26.pdf?v=1603653259",
  },
  {
    title: "Sculpt & Burn Workout Challenge",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/27_-_October_19_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1603049084",
    description: "This week we're focusing on full body, toning and sculpting with a lot of Pilates!",
    info: ["New pre-recorded follow along workouts", "Live workouts"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/27_-_LEAN_Weekly_Guide_-_October_19.pdf?v=1603048204",
  },
  {
    title: "Lean Muscle Building Bootcamp",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/26_-_October_12_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1602438189",
    description:
      "The second week of the LEAN Bootcamp! Download now to get your workouts and free nutrition pack to help you achieve your goals, and maintain them.",
    info: ["Optimum Nutrition Guide to Building Mass", "New pre-recorded follow along workouts", "Live workouts"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/26_-_LEAN_Weekly_Guide_-_ON_-_October_12.pdf?v=1602440168",
  },
  {
    title: "Lean Muscle, Fat Loss & Tone Bootcamp",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/25_-_October_5_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1601833267",
    description:
      "Introducing the new LEAN Bootcamps. Download now to get your workouts and free nutrition pack to help you achieve your goals, and maintain them.",
    info: ["Optimum Nutrition Guide to Body Maintenance", "New pre-recorded follow along workouts", "Live workouts"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/25_-_LEAN_Weekly_Guide_-_ON_-_October_5.pdf?v=1601833111",
  },
  {
    title: "Booty Lift & Waist Sculpt Challenge",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/24_-_September_28_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1601215057",
    description:
      "This is the Booty Lift and Waist Sculpt workout challenge! Get ready to work your butt and shape your waist. Get ready to burn!",
    info: ["New pre-recorded follow along workouts", "Healthy recipe", "Live workout"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/24_-_LEAN_Weekly_Guide_-_September_28_58a537e8-3ba0-47b8-b79c-5241e36e8edc.pdf?v=1601228730",
  },
  {
    title: "Lean Sculpt & Fat Burn Challenge",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/23_-_September_21_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1600610725",
    description:
      "This full body lean sculpt and fat burn workout challenge is going to get you sweating! Are you ready?",
    info: ["New pre-recorded follow along workouts", "Healthy recipe", "Live workout", "New Vlog"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/23_-_LEAN_Weekly_Guide_-_September_21.pdf?v=1600610471",
  },
  {
    title: "Legs & Core Workout Challenge",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/22_-_September_14_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1600016688",
    description: "Get ready to burn your core and sculpt your legs. This week, we're going in!",
    info: ["New pre-recorded follow along workouts", "Healthy recipe", "New Vlog"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/22_-_LEAN_Weekly_Guide_-_September_14.pdf?v=1600016832",
  },
  {
    title: "Build An Hourglass Shape Challenge",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/21_-_September_7_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1599411569",
    description: "This workout challenge is designed to help you build an hourglass shape from home!",
    info: ["New pre-recorded follow along workouts", "Live workout schedule", "Healthy recipe", "New Vlog"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/21_-_LEAN_Weekly_Guide_-_September_7.pdf?v=1599410940",
  },
  {
    title: "Full Body Shred & Intense Abs Challenge",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/20_-_August_31_-_Square_Weekly_Guide_Cover_480x480.jpg?v=1598807128",
    description: "The Full Body and Abs Workout Challenge is designed to sculpt your entire body and burn your abs!",
    info: ["New pre-recorded follow along workouts", "Live workout schedule", "New Vlog"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/20_-_LEAN_Weekly_Guide_-_August_31.pdf?v=1598807078",
  },
  {
    title: "7 Day Abs Best of the Best Challenge!",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Square_Weekly_Guide_Cover_-_August_24_480x480.jpg?v=1598211970",
    description: "The 7 Day Abs Best of the Best Challenge is designed to help you burn and tighten into your core!",
    info: ["New pre-recorded follow along workouts", "Live workout schedule", "What I Eat In A Day"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/LEAN_Weekly_Guide_-_August_24_-_7_Day_Best_of_the_Best_Abs.pdf?v=1598211263",
  },
  {
    title: "7 Day Best of the Best Challenge!",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Square_Weekly_Guide_Cover_-_August_17_480x480.jpg?v=1597607411",
    description: "The 7 Day Best of the Best Challenge is designed to work your entire body!",
    info: ["New pre-recorded follow along workouts", "What I Eat In A Day"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/LEAN_Weekly_Guide_-_August_17_-_7_Day_Best_of_the_Best.pdf?v=1597607368",
  },
  {
    title: "7 Day August Abs Challenge!",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Square_Weekly_Guide_Cover_-_August_10_480x480.jpg?v=1596990221",
    description: "The 7 Day August Abs Challenge is designed to help you burn and tighten into your core!",
    info: ["New pre-recorded follow along workouts", "Live workout schedule"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/LEAN_Weekly_Guide_-_August_10_-_7_Day_Abs_Abs_Baby.pdf?v=1596989988",
  },
  {
    title: "7 Day Fat Burn & Hourglass Sculpt Challenge",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Square_Weekly_Guide_Cover_-_July_20_480x480.jpg?v=1595186480",
    description:
      "The 7 Day Fat Burn & Hourglass Sculpt Challenge is designed to raise your heart rate, burn fat and build lean muscle to sculpt into an hourglass frame!",
    info: ["New pre-recorded follow along workouts", "Live workout schedule", "3 Healthy Recipes"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/LEAN_Guide_-_7_Day_Fat_Burn_Hourglass_Sculpt.pdf?v=1595186541",
  },
  {
    title: "14 Day Summer Shred Challenge",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Square_Weekly_Guide_Cover_-_14_Day_Summer_Shred_Challenge_480x480.jpg?v=1592752528",
    description:
      "Introducing the 14 Day Summer Shred Challenge. Are you taking on the challenge? Don't forget to hashtag #14DaySummerShred in your posts!",
    info: ["New pre-recorded follow along workouts", "Live workout schedule", "3 Healthy Recipes"],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/LEAN_Guide_-_14_Day_Summer_Shred_Challenge_V2.pdf?v=1593364696",
  },
  {
    title: "FULL BODY FAT LOSS WORKOUT PROGRAM",
    subtitle: "",
    image: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Square_-_14_Day_Fat_Loss_480x480.jpg?v=1586208785",
    description:
      "The Full Body Fat Loss Workout Program is designed to help you lose fat and tone into a lean physique. With the support of Optimum Nutrition, we have created a Program that is going to help support you through this challenging time in self-isolation. This Program includes:",
    info: [
      "Daily Live Workouts with me",
      "Extra Follow Along Workouts",
      "Healthy Self Isolation Friendly Recipes",
      "Healthy Snack Recipes",
      "Live Q and A sessions on Nutrition, Physiotherapy & Mindset Coaching",
      "A community of thousands of people all around the world, going through the same thing as you.",
    ],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Full_Body_Fat_Loss_Workout_Program_-_LEAN_x_Optimum_Nutrition.pdf?v=1587644270",
  },
  {
    title: "SELF-ISOLATION FITNESS GUIDE",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Home_Fitness_Guide_-_LEAN_x_ON_-_Min_480x480.jpg?v=1584865450",
    description:
      "The Self-Isolation Fitness Guide is designed to target all muscle groups in the body, with different styles of training across a 14-day period. With the support of Optimum Nutrition, we have created a Guide that is going to help support you through this challenging time in self-isolation. This free Guide is designed to help us come together as a community during this challenging time and spread positivity, health and happiness.",
    info: [],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Self-Isolation_Fitness_Guide_-_LEAN_x_Optimum_Nutrition.pdf?v=1586167432",
  },
  {
    title: "40 DAY SUMMER SHRED CHALLENGE",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/The_40_Day_Summer_Shred_Challenge_-_LEAN_with_Lilly_480x480.jpg?v=1582751695",
    description:
      "Welcome to your Pre Summer Shred 40 Day Challenge. This Challenge is designed to help you build & sculpt a lean, toned body, and leave you feeling energised and confident. How does the Challenge work? A new workout will be uploaded to my YouTube channel every single day. To get the best results, you can couple these workouts with the additional recommended workouts shown in your Challenge Timetable. Modifications are provided in the new workouts. Regardless of what level you are at, listen to your body, this timetable is a guide only and you can adapt the workouts if you need to.",
    info: [],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/40_Day_Summer_Shred_Challenge_-_LEAN.pdf?v=1583753817",
  },
  {
    title: "28 DAY FAT BURN & SCULPT CHALLENGE",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/28_Day_Fat_Burn_Sculpt_Challenge_-_Cover_480x480.jpg?v=1568663631",
    description:
      "Works every muscle group in your body, this challenge sculpts and tones lean legs, arms, abs, back and a lifted booty. Including 5 different styles of training to keep you motivated and get results: Fat Burn HIIT, AMRAP, Resistance, Pilates and Active Recovery. 28 x 28min Real Time Workouts with me",
    info: [],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/28_Day_Fat_Burn_and_Sculpt_-_Lean_With_Lilly.pdf?4553",
  },
  {
    title: "30 DAY FITNESS CHALLENGE",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Lean_With_Lilly_-_30_Day_Challenge_480x480.jpg?v=1568570958",
    description:
      "Designed to work the entire body, with 4 different training styles: Fat Burn HIIT, Resistance, Pilates and Active Recovery. 30 x 30 minute Real Time workouts with me.",
    info: [],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/30_Day_Fitness_Challenge_-_Lean_With_Lilly.pdf?4527",
  },
  {
    title: "11 LINE ABS CHALLENGE",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/11_Line_Abs_Challenge_-_Lean_With_Lilly_Cover_480x480.jpg?v=1569676105",
    description:
      "This Challenge is focused on carving deep into those core muscles, 11 workouts, all 11 minutes long. If you want to get them poppin' abs this will definitely put you on the right track!",
    info: [],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/11_Line_Abs_Challenge_-_Lean_With_Lilly.pdf?v=1569680069",
  },
  {
    title: "10 DAY HOURGLASS SCULPT CHALLENGE",
    subtitle: "",
    image:
      "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/Lean_With_Lilly_-_10_Day_Hourglass_Challenge_480x480.jpg?v=1568571058",
    description:
      "A unique guide designed to kick start your journey to building an hourglass shape. This guide is made up of 6 Real Time Workouts and tips on how to build an X frame physique.",
    info: [],
    link: "https://cdn.shopify.com/s/files/1/0267/1381/7193/files/10_Day_Hourglass_Sculpt_LeanWithLilly.01.pdf?4527",
  },
];

const WorkoutGuidesPage = () => {
  return (
    <>
      <SEO title="Free Workout Guides" />

      <HeroSection
        title="FREE WORKOUT GUIDES"
        cta="BROWSE GUIDES"
        path="/pages/workout-guides#browse-guides"
        desktopImg={workoutGuides}
        mobileImg={workoutGuides}
      />

      <div id="browse-guides">
        <SectionHeader
          title={["BROWSE OUR FREE", "WORKOUT GUIDES"]}
          description={[
            "Get started with premium LEAN membership and you’ll get full access to all the fabulous features the app has to offer.",
            "From educational content to workout classes and a fresh drop of recipes, challenges and workouts every single month, the LEAN app is something that will have you coming back again and again.",
          ]}
        />
      </div>

      {data.map((props, index) => (
        <>
          <WorkoutGuideSection key={index} index={index} {...props} />

          {index === 0 && (
            <ClippedPathContainer bg="darkTeal">
              <Grid
                display={["block", "block", "grid"]}
                maxWidth="1100px"
                margin="0 auto"
                gridTemplateColumns={"1fr 1fr 1fr"}
                gridGap={[2]}
                px={3}
                py={[1, 1, 1]}
                pt={[5, 5, 5]}
                pb={[2]}
                overflow="hidden"
              >
                <Box>
                  <Flex height="100%" flexDirection="column">
                    <Flex height="100%" alignItems={"flex-start"}>
                      <SectionHeader
                        title={"We offer great features in Lean App"}
                        description={[
                          "Get started with premium LEAN membership and you’ll get full access to all the fabulous features the app has to offer.",
                          "From educational content to workout classes and a fresh drop of recipes, challenges and workouts every single month, the LEAN app is something that will have you coming back again and again.",
                        ]}
                        textAlign={"left"}
                        cta={"JOIN NOW"}
                        path={"/join"}
                        color={"darkTeal"}
                        my={0}
                      ></SectionHeader>
                    </Flex>
                  </Flex>
                </Box>

                <Box>
                  <Flex height="100%" alignItems={"flex-start"}>
                    <div
                      style={{
                        height: "auto",
                        width: "100%",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="comparative-table">
                        <div className="benefits-table">
                          <table>
                            <tr>
                              <td></td>
                              <td>
                                <span className="td-title">YOUTUBE</span>
                              </td>
                              <td>
                                <span className="td-title">PREMIUM</span>
                              </td>
                            </tr>

                            <tr className="underline">
                              <td>Nutrition Tracking</td>
                              <td>
                                <div>
                                  <img className="cloud" src={checkIconGray} />
                                </div>
                              </td>
                              <td>
                                <div>
                                  <img className="cloud" src={checkIconGreen} />
                                </div>
                              </td>
                            </tr>
                            <tr className="underline">
                              <td>Customizable Carbs, protein & Fat Goals</td>
                              <td></td>
                              <td>
                                <div>
                                  <img className="cloud" src={checkIconGreen} />
                                </div>
                              </td>
                            </tr>

                            <tr className="underline">
                              <td>Guided fitness & Nutrition Plans </td>
                              <td></td>
                              <td>
                                <div>
                                  <img className="cloud" src={checkIconGreen} />
                                </div>
                              </td>
                            </tr>

                            <tr className="underline">
                              <td>Custom Home Screen Dashboard</td>
                              <td></td>
                              <td>
                                <div>
                                  <img className="cloud" src={checkIconGreen} />
                                </div>
                              </td>
                            </tr>

                            <tr className="underline">
                              <td>Food Analysis & Insights </td>
                              <td></td>
                              <td>
                                <div>
                                  <img className="cloud" src={checkIconGreen} />
                                </div>
                              </td>
                            </tr>

                            <tr className="underline">
                              <td>Meals & Recipes</td>
                              <td></td>
                              <td>
                                <div>
                                  <img className="cloud" src={checkIconGreen} />
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>Ad-Free Experience</td>
                              <td></td>
                              <td>
                                <div>
                                  <img className="cloud" src={checkIconGreen} />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Flex>
                </Box>

                <Flex position="relative" style={{ order: 0 }} mt={[3, 3, 0]}>
                  <img src={appFeatures} width="100%" />
                </Flex>
              </Grid>
            </ClippedPathContainer>
          )}

          {index === 1 && (
            <ImageTextSection
              header={<AppStatistics />}
              title="GET ME RESULTS"
              description="Familia, we all have different goals and reasons for working out which is why the LEAN App has been designed to be totally personalized to you. "
              cta="JOIN NOW"
              path="/join"
              reverse
              img={resultsImg}
            />
          )}

          {index === 3 && (
            <>
              <SectionHeader
                title="LOOKING TO LEVEL UP YOUR RESULTS?"
                subtitle="Shop the LEAN Nutrition Range to keep you energised and take care of your body pre/post workouts. Our fitness range allows you to workout comfortably from home and add equipment to spice up your workout routine."
              />

              <CategoryGrid categories={categories} />
            </>
          )}
        </>
      ))}

      <FreeGuidesForm />
    </>
  );
};

export default WorkoutGuidesPage;
