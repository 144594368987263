import React, { useState } from "react";
import { Button } from "../../elements/Button";
import { Flex } from "../../elements/Flex";
import { Image } from "../../elements/Image";
import { Text } from "../../elements/Text";
import { Ul } from "../../elements/Ul";
import { Li } from "../../elements/Li";
import { ExternalLink } from "../../elements/ExternalLink";
import { Grid } from "../../elements/Grid";
import arrowDown from "../../images/icons/arrow-down.svg";

const WorkoutGuideSection = ({ image, title, subtitle, description, info, moreInfo, link, index }) => {
  const isString = typeof description === "string";

  const [moreInfoExpanded, setMoreInfoExpanded] = useState(false);
  return (
    <Grid
      maxWidth="1200px"
      margin="0 auto"
      mb={5}
      display={["block", "block", "grid"]}
      gridTemplateColumns="1fr 1fr"
      gridGap={3}
      px={3}
    >
      <Flex style={{ order: index === 0 ? 1 : index % 2 ? -1 : 1 }}>
        <ExternalLink mx="auto" href={link} target="_blank" rel="noopener noreferrer">
          <Image src={image} width="100%" maxWidth="500px" height="auto" style={{ borderRadius: 15 }} />
        </ExternalLink>
      </Flex>

      <Flex textAlign="left" justifyContent="center" alignItems="flex-start" flexDirection="column">
        <Text fontWeight="600" style={{ color: "#444" }} fontSize={32} textTransform="uppercase" mt={3}>
          {title}
        </Text>
        <Text fontWeight="600" style={{ color: "#444" }} fontSize={24}>
          {subtitle}
        </Text>

        {isString ? (
          <Text mx="auto" maxWidth="900px" my={3} mt={3}>
            {description}
          </Text>
        ) : (
          description
        )}

        {moreInfo && (
          <>
            {!moreInfoExpanded ? (
              <Text
                fontWeight="600"
                fontSize={16}
                mt={4}
                style={{ color: "#01a8ac", display: "flex", justifyContent: "center", cursor: "pointer" }}
                onClick={() => setMoreInfoExpanded(true)}
              >
                More info
                <img src={arrowDown} style={{ marginLeft: "10px" }} />
              </Text>
            ) : (
              moreInfo
            )}
          </>
        )}

        {/* <Ul>
          {info?.map((item, index) => (
            <Li key={index}>{item}</Li>
          ))}
        </Ul> */}

        <ExternalLink textDecoration="none" href={link} target="_blank" rel="noopener noreferrer">
          <Button
            mx="auto"
            bg="#01a8ac"
            color="white"
            variant="rounded"
            mt={4}
            style={{ padding: "15px 25px", fontSize: 12 }}
          >
            DOWNLOAD THE GUIDE
          </Button>
        </ExternalLink>
      </Flex>
    </Grid>
  );
};

export default WorkoutGuideSection;
